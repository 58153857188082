import React, { useContext, useState } from 'react';
import { AppContext, ACTION_TYPES, MODES } from '../App';

import API, { graphqlOperation } from '@aws-amplify/api';
import { updateTinderUser } from '../graphql/mutations';

async function updateUser(input) {
    const userData = await API.graphql(graphqlOperation(updateTinderUser,
        { input: { ...input, justCreated: false } }));
    return userData.data.updateTinderUser;
}

function ProfileEdit() {
    const { state, dispatch } = useContext(AppContext);
    const [nameInput, setNameInput] = useState((state.activeUser) ? state.activeUser.name : '');
    const [profileTextInput, setProfileTextInput] = useState((state.activeUser) ? state.activeUser.profileText : '');
    const [imageUrlInput, setImageUrlInput] = useState((state.activeUser) ? state.activeUser.imageUrl : '');

    async function handleSubmitUserInfo(event) {
        event.preventDefault(); // don't reload the page
        let user = await updateUser({
            username: state.activeUser.username,
            session: state.activeSession.name,
            ...(nameInput) && { name: nameInput },
            ...(profileTextInput) && { profileText: profileTextInput },
            ...(imageUrlInput) && { imageUrl: imageUrlInput }
        });
        dispatch({ type: ACTION_TYPES.SET_USER, user: user });
        dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.DEFAULT });
    }

    return (
        <form onSubmit={handleSubmitUserInfo}>
            <label>
                <div className="inputPrompt">Character Name:</div>
                {state.activeSession.anonymous &&
                    <div className="helperText">Your character's real name. Since this session is anonymous, this won't be shown to others until you match.</div>}
                <input type="text" value={nameInput} required={true}
                    onChange={(event) => { setNameInput(event.target.value) }} />
            </label>
            <br />
            <label>
                <div className="inputPrompt">Profile Image URL:</div>
                {state.activeSession.anonymous &&
                    <div className="helperText">Since this session is anonymous, please choose an image from here:
                    <a href="https://www.pexels.com/search/animal/" target="_blank">https://www.pexels.com/search/animal/</a></div>}
                <input type="URL" value={imageUrlInput}
                    onChange={(event) => { setImageUrlInput(event.target.value) }} />
            </label>
            <br />
            <label>
                <div className="inputPrompt">Profile Text:</div>
                <textarea value={profileTextInput} maxLength="500" placeholder="500 characters max"
                    onChange={(event) => { setProfileTextInput(event.target.value) }} />
            </label>
            <input type="submit" value="Submit" />
        </form>
    )
}

export default ProfileEdit;