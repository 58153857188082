/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTinderUser = /* GraphQL */ `
  query GetTinderUser($username: String!, $session: String!) {
    getTinderUser(username: $username, session: $session) {
      username
      session
      name
      profileText
      imageUrl
      justCreated
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const listTinderUsers = /* GraphQL */ `
  query ListTinderUsers(
    $username: String
    $session: ModelStringKeyConditionInput
    $filter: ModelTinderUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTinderUsers(
      username: $username
      session: $session
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        session
        name
        profileText
        imageUrl
        justCreated
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTinderSwipe = /* GraphQL */ `
  query GetTinderSwipe($swipingUsername: String!, $swipedUsername: String!) {
    getTinderSwipe(
      swipingUsername: $swipingUsername
      swipedUsername: $swipedUsername
    ) {
      swipingUsername
      swipedUsername
      liked
      createdAt
      updatedAt
    }
  }
`;
export const listTinderSwipes = /* GraphQL */ `
  query ListTinderSwipes(
    $swipingUsername: String
    $swipedUsername: ModelStringKeyConditionInput
    $filter: ModelTinderSwipeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTinderSwipes(
      swipingUsername: $swipingUsername
      swipedUsername: $swipedUsername
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        swipingUsername
        swipedUsername
        liked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTinderSession = /* GraphQL */ `
  query GetTinderSession($name: String!) {
    getTinderSession(name: $name) {
      name
      anonymous
      swipeEnabled
      matchEnabled
      hidden
      createdAt
      updatedAt
    }
  }
`;
export const listTinderSessions = /* GraphQL */ `
  query ListTinderSessions(
    $name: String
    $filter: ModelTinderSessionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTinderSessions(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        anonymous
        swipeEnabled
        matchEnabled
        hidden
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersBySession = /* GraphQL */ `
  query UsersBySession(
    $session: String
    $sortDirection: ModelSortDirection
    $filter: ModelTinderUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersBySession(
      session: $session
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        session
        name
        profileText
        imageUrl
        justCreated
        isDeleted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const swipersBySwiped = /* GraphQL */ `
  query SwipersBySwiped(
    $swipedUsername: String
    $sortDirection: ModelSortDirection
    $filter: ModelTinderSwipeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    swipersBySwiped(
      swipedUsername: $swipedUsername
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        swipingUsername
        swipedUsername
        liked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
