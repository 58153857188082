import React, { useContext, useState } from 'react';
import { AppContext, ACTION_TYPES, MODES } from '../App';

import API, { graphqlOperation } from '@aws-amplify/api';
import { createTinderUser } from '../graphql/mutations';
import { getTinderUser } from '../graphql/queries';

import { generateFakeName } from '../utils/anonymousGenerators';

import UserProfile from './UserProfile';
import './User.css';

async function getUser({ username, sessionName }) {
    const userData = await API.graphql(graphqlOperation(getTinderUser,
        { username: username, session: sessionName }));
    return userData.data.getTinderUser;
}

async function createNewUser({ username, sessionName }) {
    const userData = await API.graphql(graphqlOperation(createTinderUser,
        { input: { username: username, session: sessionName, justCreated: true } }));
    return userData.data.createTinderUser;
}

function DefaultView() {
    const { state, dispatch } = useContext(AppContext);
    const [usernameInput, setUsernameInput] = useState('');

    async function handleSubmitUsername(event) {
        event.preventDefault(); // don't reload the page
        const username = usernameInput.trim().toLowerCase();
        if (username === "admin") {
            dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.ADMIN });
            return;
        }
        let user = await getUser({ username: username, sessionName: state.activeSession.name });
        if (!user) {
            user = await createNewUser({ username: username, sessionName: state.activeSession.name });
        }
        if (!user.name) {
            dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.EDIT_PROFILE });
        } else {
            dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.DEFAULT });
        }
        dispatch({ type: ACTION_TYPES.SET_USER, user: user });
    }

    async function handleEdit() {
        dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.EDIT_PROFILE });
    }
    async function handleStartSwiping() {
        dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.SWIPE });
    }
    async function handleViewMatches() {
        dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.VIEW_MATCHES });
    }

    let displayName = '';
    if (state.activeUser) {
        displayName = state.activeSession.anonymous
            ? state.activeUser.name + " aka " + generateFakeName(state.activeUser.name, state.activeSession.name)
            : state.activeUser.name;
    } 

    return (
        <div>
            {state.activeUser == null &&
                <form onSubmit={handleSubmitUsername}>
                    <label>
                    <div className="inputPrompt">Enter your character's username:</div>
						<input type="text" value={usernameInput} 
                            onChange={(event) => { setUsernameInput(event.target.value) }} />
                    </label>
                    <input type="submit" value="Submit" />
                </form>
            }
            {state.activeUser != null &&
                <div>
                    <UserProfile name={displayName} imageUrl={state.activeUser.imageUrl} profileText={state.activeUser.profileText} />
                    <button onClick={handleEdit}>Edit Profile</button>
                    {state.activeSession.swipeEnabled && <button onClick={handleStartSwiping}>Start Swiping</button>}
                    {state.activeSession.matchEnabled && <button onClick={handleViewMatches}>View Matches</button>}
                </div>  
            }
        </div>
    );
}

export default DefaultView;