/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTinderUser = /* GraphQL */ `
  mutation CreateTinderUser(
    $input: CreateTinderUserInput!
    $condition: ModelTinderUserConditionInput
  ) {
    createTinderUser(input: $input, condition: $condition) {
      username
      session
      name
      profileText
      imageUrl
      justCreated
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const updateTinderUser = /* GraphQL */ `
  mutation UpdateTinderUser(
    $input: UpdateTinderUserInput!
    $condition: ModelTinderUserConditionInput
  ) {
    updateTinderUser(input: $input, condition: $condition) {
      username
      session
      name
      profileText
      imageUrl
      justCreated
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteTinderUser = /* GraphQL */ `
  mutation DeleteTinderUser(
    $input: DeleteTinderUserInput!
    $condition: ModelTinderUserConditionInput
  ) {
    deleteTinderUser(input: $input, condition: $condition) {
      username
      session
      name
      profileText
      imageUrl
      justCreated
      isDeleted
      createdAt
      updatedAt
    }
  }
`;
export const createTinderSwipe = /* GraphQL */ `
  mutation CreateTinderSwipe(
    $input: CreateTinderSwipeInput!
    $condition: ModelTinderSwipeConditionInput
  ) {
    createTinderSwipe(input: $input, condition: $condition) {
      swipingUsername
      swipedUsername
      liked
      createdAt
      updatedAt
    }
  }
`;
export const updateTinderSwipe = /* GraphQL */ `
  mutation UpdateTinderSwipe(
    $input: UpdateTinderSwipeInput!
    $condition: ModelTinderSwipeConditionInput
  ) {
    updateTinderSwipe(input: $input, condition: $condition) {
      swipingUsername
      swipedUsername
      liked
      createdAt
      updatedAt
    }
  }
`;
export const deleteTinderSwipe = /* GraphQL */ `
  mutation DeleteTinderSwipe(
    $input: DeleteTinderSwipeInput!
    $condition: ModelTinderSwipeConditionInput
  ) {
    deleteTinderSwipe(input: $input, condition: $condition) {
      swipingUsername
      swipedUsername
      liked
      createdAt
      updatedAt
    }
  }
`;
export const createTinderSession = /* GraphQL */ `
  mutation CreateTinderSession(
    $input: CreateTinderSessionInput!
    $condition: ModelTinderSessionConditionInput
  ) {
    createTinderSession(input: $input, condition: $condition) {
      name
      anonymous
      swipeEnabled
      matchEnabled
      hidden
      createdAt
      updatedAt
    }
  }
`;
export const updateTinderSession = /* GraphQL */ `
  mutation UpdateTinderSession(
    $input: UpdateTinderSessionInput!
    $condition: ModelTinderSessionConditionInput
  ) {
    updateTinderSession(input: $input, condition: $condition) {
      name
      anonymous
      swipeEnabled
      matchEnabled
      hidden
      createdAt
      updatedAt
    }
  }
`;
export const deleteTinderSession = /* GraphQL */ `
  mutation DeleteTinderSession(
    $input: DeleteTinderSessionInput!
    $condition: ModelTinderSessionConditionInput
  ) {
    deleteTinderSession(input: $input, condition: $condition) {
      name
      anonymous
      swipeEnabled
      matchEnabled
      hidden
      createdAt
      updatedAt
    }
  }
`;
