import React from 'react';

function UserProfile({ name, imageUrl, profileText }) {
    return (<div className="User">
        <div>
            <img alt="user profile" src={imageUrl} className="profileImage" />
        </div>
        <div className="profileName">
            <b>{name}</b>
        </div>
        {profileText && 
        <div className="profileText">
            {profileText.split('\n').map((item, i) => { return <p key={i}>{item}</p> }) }
        </div>}
    </div>
    );
}

export default UserProfile;