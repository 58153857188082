import React, { useContext, useState } from 'react';
import { AppContext } from '../App';

import API, { graphqlOperation } from '@aws-amplify/api';
import { createTinderSession, updateTinderSession } from '../graphql/mutations';

import SessionSelector from './SessionSelector';

async function createNewSession(input) {
    await API.graphql(graphqlOperation(createTinderSession, { input: input }));
}

async function updateSession(input) {
    await API.graphql(graphqlOperation(updateTinderSession, { input: input }));
}

function SessionAdminConsole() {
    const { state, dispatch } = useContext(AppContext);
    const [nameInput, setNameInput] = useState(state.activeSession.name);
    const [anonymousInput, setAnonymousInput] = useState(state.activeSession.anonymous);
    const [swipeEnabledInput, setSwipeEnabledInput] = useState(state.activeSession.swipeEnabled);
    const [matchEnabledInput, setMatchEnabledInput] = useState(state.activeSession.matchEnabled);
    const [hiddenInput, setHiddenInput] = useState(state.activeSession.hidden);

    async function handleUpdateSession(event) {
        event.preventDefault(); // don't reload the page
        await updateSession({
            name: nameInput,
            anonymous: anonymousInput,
            swipeEnabled: swipeEnabledInput,
            matchEnabled: matchEnabledInput,
            hidden: hiddenInput
        });
    }

    async function handleNewSession(event) {
        event.preventDefault(); // don't reload the page
        await createNewSession({
            name: nameInput,
            anonymous: anonymousInput,
            swipeEnabled: swipeEnabledInput,
            matchEnabled: matchEnabledInput,
            hidden: hiddenInput
        });
    }

    return (
        <div className="adminConsole">
            <SessionSelector showHidden={true} />
            <div className="inputPrompt">Update Session:</div>
            <form onSubmit={handleUpdateSession}>
                <label>
                    <div className="inputPrompt">Name:</div>
                    <input type="text" value={nameInput}
                        onChange={(event) => { setNameInput(event.target.value) }} />
                    <br />
                    Anonymous:
                <input type="checkbox" checked={anonymousInput}
                        onChange={() => { setAnonymousInput(!anonymousInput) }} />
                    <br />
                    Swipes Enabled:
                <input type="checkbox" checked={swipeEnabledInput}
                        onChange={() => { setSwipeEnabledInput(!swipeEnabledInput) }} />
                    <br />
                    Matches Enabled:
                <input type="checkbox" checked={matchEnabledInput}
                        onChange={() => { setMatchEnabledInput(!matchEnabledInput) }} />
                    <br />
                    Hidden:
                <input type="checkbox" checked={hiddenInput}
                        onChange={() => { setHiddenInput(!hiddenInput) }} />
                </label>
                    <br />
                <input type="submit" value="Submit" />
            </form>
            <div className="inputPrompt">New Session:</div>
            <form onSubmit={handleNewSession}>
                <label>
                    <div className="inputPrompt">Name:</div>
                    <input type="text" value={nameInput}
                        onChange={(event) => { setNameInput(event.target.value) }} />
                    <br />
                    Anonymous:
                <input type="checkbox" checked={anonymousInput}
                        onChange={(event) => { setAnonymousInput(!anonymousInput) }} />
                    <br />
                    Swipes Enabled:
                <input type="checkbox" checked={swipeEnabledInput}
                        onChange={(event) => { setSwipeEnabledInput(!swipeEnabledInput) }} />
                    <br />
                    Matches Enabled:
                <input type="checkbox" checked={matchEnabledInput}
                        onChange={() => { setMatchEnabledInput(!matchEnabledInput) }} />
                    Hidden:
                <input type="checkbox" checked={hiddenInput}
                        onChange={(event) => { setHiddenInput(!hiddenInput) }} />
                </label>
                <br />
                <input type="submit" value="Submit" />
            </form>
        </div>
     );
}

export default SessionAdminConsole;