import React, { createContext, useReducer } from 'react';

import API from '@aws-amplify/api';
import PubSub from '@aws-amplify/pubsub';
import awsconfig from './aws-exports';

import './App.css';
import SessionAdminConsole from './components/SessionAdminConsole';
import SessionSelector from './components/SessionSelector';
import DefaultView from './components/DefaultView';
import ProfileEdit from './components/ProfileEdit';
import SwipeView from './components/SwipeView';
import MatchView from './components/MatchView';

// Configure Amplify
API.configure(awsconfig);
PubSub.configure(awsconfig);

export const ACTION_TYPES = {
    QUERY_SESSIONS: 'QUERY_SESSIONS',
    QUERY_SWIPES_BY_ME: 'QUERY_SWIPES_BY_ME',
    QUERY_SWIPES_BY_OTHERS: 'QUERY_SWIPES_BY_OTHERS',
    QUERY_USERS: 'QUERY_USERS',
    SET_SESSION: 'SET_SESSIONS',
    SET_USER: 'SET_USER',
    SET_MODE: 'SET_MODE',
    SWIPE: 'SWIPE'
}

export const MODES = {
    INIT: 'INIT',
    DEFAULT: 'DEFAULT',
    EDIT_PROFILE: 'EDIT_PROFILE',
    SWIPE: 'SWIPE',
    VIEW_MATCHES: 'VIEW_MATCHES'
}

const initialState = {
    mode: MODES.INIT,
    sessions: {},
    activeSession: null,
    users: [],
    activeUser: null,
    swipesByMe: [],
    swipesLikedMe: []
}

const reducer = (state, action) => {
    switch (action.type) {

        case ACTION_TYPES.SET_MODE:
            return { ...state, mode: action.mode };
        case ACTION_TYPES.SET_SESSION:
            return { ...state, activeSession: action.session }
        case ACTION_TYPES.SET_USER:
            return { ...state, activeUser: action.user };
        case ACTION_TYPES.SWIPE:
            return { ...state, swipesByMe: { ...state.swipesByMe, [action.swipe.swipedUsername]: action.swipe } };
        case ACTION_TYPES.QUERY_SESSIONS:
            return { ...state, sessions: action.sessions };
        case ACTION_TYPES.QUERY_SWIPES_BY_ME:
            return { ...state, swipesByMe: action.swipes};
        case ACTION_TYPES.QUERY_SWIPES_BY_OTHERS:
            return { ...state, swipesLikedMe: action.swipes };
        case ACTION_TYPES.QUERY_USERS:
            return { ...state, users: action.users };

        default:
            return state;
    }
};

export const AppContext = createContext();

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    //                {state.mode === MODES.SWIPE && <SwipeView />}
	return (
        <AppContext.Provider value={{ state, dispatch }}>
            <div className="Header">
                {state.activeSession && <h1>{state.activeSession.name} Tinder!</h1>}
            </div>
            <div className="App">
                {state.mode === MODES.INIT && <SessionSelector />}
                {state.mode === MODES.EDIT_PROFILE && <ProfileEdit />}
                {state.mode === MODES.ADMIN && <SessionAdminConsole />}
                {state.mode === MODES.DEFAULT && <DefaultView />}
                {state.mode === MODES.SWIPE && <SwipeView />}
                {state.mode === MODES.VIEW_MATCHES && <MatchView />}
            </div>
        </AppContext.Provider>
	);
}

export default App;
