import React, { useContext, useEffect, useState } from 'react';
import { AppContext, ACTION_TYPES, MODES } from '../App';
import API, { graphqlOperation } from '@aws-amplify/api';
import { createTinderSwipe } from '../graphql/mutations';
import { listTinderSwipes, usersBySession } from '../graphql/queries';
import { onUpdateTinderUser } from '../graphql/subscriptions';
import { CSSTransition } from 'react-transition-group';

import { generateFakeName } from '../utils/anonymousGenerators';

import UserProfile from './UserProfile';
import './User.css';

function SwipeView() {
    const { state, dispatch } = useContext(AppContext);
    const [swipesLoaded, setSwipesLoaded] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);
    let usersToSwipe, viewUser;

    useEffect(() => {
        async function getUsers() {
            const userData = await API.graphql(graphqlOperation(usersBySession, { session: state.activeSession.name, limit: 1000 }));
            //console.log(userData);
            const usersByUsername = userData.data.usersBySession.items.filter(item => (!item.justCreated && !item.isDeleted))
                .reduce((accumulated, current) => { return { ...accumulated, [current.username]: current } }, {});
            //console.log(usersByUsername);
            dispatch({ type: ACTION_TYPES.QUERY_USERS, users: usersByUsername });
        }
        getUsers();

    }, []);

    useEffect(() => {
        async function getSwipes() {
            const swipeData = await API.graphql(graphqlOperation(listTinderSwipes, { swipingUsername: state.activeUser.username, limit: 1000 }));
            const swipesBySwipedUser = swipeData.data.listTinderSwipes.items.reduce((accumulated, current) => { return { ...accumulated, [current.swipedUsername]: current } },
                {});
            dispatch({ type: ACTION_TYPES.QUERY_SWIPES_BY_ME, swipes: swipesBySwipedUser });
        }
        getSwipes();
        setSwipesLoaded(true);
        setFadeIn(true);

        //const subscription = API.graphql(graphqlOperation(onCreateTinderSwipe)).subscribe({
        //    next: (eventData) => {
        //        const swipe = eventData.value.data.onCreateTinderSwipe;
        //        dispatch({ type: ACTION_TYPES.SUBSCRIPTION_SWIPES, swipe: { [swipe.swipedUsername]: swipe } });
        //    }
        //});

        //return () => subscription.unsubscribe();
    }, []);

    async function handleLike() {
        const swipe = { swipingUsername: state.activeUser.username, swipedUsername: viewUser.username, liked: true };
        await API.graphql(graphqlOperation(createTinderSwipe, { input: swipe }));
        dispatch({ type: ACTION_TYPES.SWIPE, swipe });
        setFadeIn(true);
    }

    async function handleDislike() {
        const swipe = { swipingUsername: state.activeUser.username, swipedUsername: viewUser.username, liked: false };
        await API.graphql(graphqlOperation(createTinderSwipe, { input: swipe }));
        dispatch({ type: ACTION_TYPES.SWIPE, swipe });
        setFadeIn(true);
    }

    async function handleGoBack() {
        dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.DEFAULT });
    }

    let done = false;
    if (state.users && swipesLoaded) {
        usersToSwipe = new Set(Object.keys(state.users));
        usersToSwipe.delete(state.activeUser.username); //don't need to swipe ourself
        if (state.swipesByMe) {
            //console.log(Object.keys(state.swipesByMe));
            for (let u of Object.keys(state.swipesByMe).sort()) {
                usersToSwipe.delete(u);
            }
        }
        if (usersToSwipe.size > 0) {
            const username = usersToSwipe.values().next().value;
            //console.log(username);
            viewUser = state.users[username];
        } else {
            done = true;
        }
    } 
    //console.log(usersToSwipe);

    return (

        <div>
            {viewUser && 
                <CSSTransition in={fadeIn} timeout={200} classNames="my-node">
                    <div>
                    <UserProfile name={generateFakeName(viewUser.name, state.activeSession.name)} imageUrl={viewUser.imageUrl} profileText={viewUser.profileText} />
                        <div className="swipeButtons">
                            <div className="dislike" onClick={handleDislike}>
                                <img alt="dislike" src="https://i.imgur.com/JKWWpNv.png" />
                            </div>
                            <div className="spacer">&nbsp;</div>
                            <div className="like" onClick={handleLike}>
                                <img alt="like" src="https://i.imgur.com/eshnnxp.png" />
                            </div>
                        </div>
                        <div className="counterText">You have {usersToSwipe.size} {(usersToSwipe.size) === 1 ? "profile" : "profiles"} left to rate.</div>
                    </div>
                </CSSTransition>
            }
            {done && <div className="counterText">You've rated everyone!</div>}
            <button onClick={handleGoBack}>Go Back</button>
        </div>
    );
}

export default SwipeView;