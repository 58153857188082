import React, { useContext, useEffect } from 'react';
import { AppContext, ACTION_TYPES, MODES } from '../App';

import API, { graphqlOperation } from '@aws-amplify/api';
import { listTinderSessions } from '../graphql/queries'

function SessionSelector({ showHidden = false }) {
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        async function getSessions() {
            const sessionData = await API.graphql(graphqlOperation(listTinderSessions));
            const sessionsByName = sessionData.data.listTinderSessions.items.reduce(
                (accumulated, current) => {
                    return (showHidden || !current.hidden) ?
                        { ...accumulated, [current.name]: current }
                        : accumulated;
                },
                {}
            );
            dispatch({ type: ACTION_TYPES.QUERY_SESSIONS, sessions: sessionsByName });
        }
        getSessions();
    }, []);

    useEffect(() => {
        async function selectOnlySession(session) {
            dispatch({ type: ACTION_TYPES.SET_SESSION, session: session });
            dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.DEFAULT });
        }
        if (!state.activeSession && Object.keys(state.sessions).length === 1) {
            selectOnlySession(state.sessions[Object.keys(state.sessions)[0]])
        }
    }, [state.activeSession, state.sessions]);

    async function handleSelectSession(event) {
        dispatch({ type: ACTION_TYPES.SET_SESSION, session: state.sessions[event.target.value] })
        dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.DEFAULT });
    }

    return (<div>
                <form>
                    <label>
                        <div className="inputPrompt">Select a session:</div>
                        <select onChange={handleSelectSession}>
                            <option value=""></option>
                            {Object.keys(state.sessions).map(name =>
                                <option value={name} key={name}>{name}</option>
                            )}
                        </select>
                    </label>
                </form>
        </div>
    );
}

export default SessionSelector;