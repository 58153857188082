import React, { useContext, useEffect, useState } from 'react';
import { AppContext, ACTION_TYPES, MODES } from '../App';
import API, { graphqlOperation } from '@aws-amplify/api';
import { listTinderSwipes, swipersBySwiped, usersBySession } from '../graphql/queries';

import { generateFakeName } from '../utils/anonymousGenerators';

import UserProfile from './UserProfile';
import './User.css';


function MatchView() {
    const { state, dispatch } = useContext(AppContext);
    const [swipesLoaded, setSwipesLoaded] = useState(false);
    const [usersLoaded, setUsersLoaded] = useState(false);

    useEffect(() => {
        async function getUsers() {
            const userData = await API.graphql(graphqlOperation(usersBySession, { session: state.activeSession.name, limit: 1000 }));
            const usersByUsername = userData.data.usersBySession.items.filter(item => (!item.justCreated && !item.isDeleted))
                .reduce((accumulated, current) => { return { ...accumulated, [current.username]: current } }, {});
            dispatch({ type: ACTION_TYPES.QUERY_USERS, users: usersByUsername });
        }
        getUsers();
        setUsersLoaded(true);
    }, []);

    useEffect(() => {
        async function getSwipesByMe() {
            const swipeData = await API.graphql(graphqlOperation(listTinderSwipes, { swipingUsername: state.activeUser.username, limit: 1000 }));
            const swipesBySwipedUser = swipeData.data.listTinderSwipes.items.reduce((accumulated, current) => { return { ...accumulated, [current.swipedUsername]: current } },
                {});
            dispatch({ type: ACTION_TYPES.QUERY_SWIPES_BY_ME, swipes: swipesBySwipedUser });
        }
        async function getSwipesLikedMe() {
            const swipeData = await API.graphql(graphqlOperation(swipersBySwiped, {
                swipedUsername: state.activeUser.username,
                limit: 1000,
                filter: { liked: { eq: true } } }));
            const swipesBySwipingUser = swipeData.data.swipersBySwiped.items.reduce((accumulated, current) => { return { ...accumulated, [current.swipingUsername]: current } },
                {});
            dispatch({ type: ACTION_TYPES.QUERY_SWIPES_BY_OTHERS, swipes: swipesBySwipingUser });
        }
        getSwipesByMe();
        getSwipesLikedMe();
        setSwipesLoaded(true);
    }, []);

    async function handleGoBack() {
        dispatch({ type: ACTION_TYPES.SET_MODE, mode: MODES.DEFAULT });
    }

    let matchUsernames = [];
    if (state.users && swipesLoaded) {
        let usernamesLikedMe = new Set(Object.keys(state.swipesLikedMe));
        //console.log(usernamesLikedMe);
        if (state.swipesByMe) {
            //console.log(Object.keys(state.swipesByMe));
            for (let u of Object.keys(state.swipesByMe).sort()) {
                if (state.swipesByMe[u].liked === true && usernamesLikedMe.has(u)) {
                    matchUsernames.push(u);
                }
            }
        }
    }
    //console.log(matchUsernames);
    //console.log(state.users);

    const loaded = swipesLoaded && (Object.keys(state.users).length > 0);

    return (
        <div>
            {loaded && (matchUsernames.length > 0) && 
                <div>
                <div className="counterText">You have {matchUsernames.length} {(matchUsernames.length) === 1 ? "match" : "matches"}!</div>
                    {matchUsernames.map((username) => {
                        return <UserProfile
                            name={state.users[username].name + " aka " + generateFakeName(state.users[username].name, state.activeSession.name)}
                            imageUrl={state.users[username].imageUrl}
                            profileText={state.users[username].profileText} />
                    })}
                </div>
            }
            {loaded && (matchUsernames.length === 0) && <div className="counterText">Sorry, you have no matches. :(</div>}
            {!loaded && <div>Loading...</div>}
            <button onClick={handleGoBack}>Go Back</button>
        </div>
    );
}

export default MatchView;